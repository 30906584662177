import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VirtualCardService {
  public url: string;
  constructor(
    private http: HttpClient
  ) {
    // Url de la api
    this.url = environment.urlServices;
   }

   // Metodo para obtener una tarjeta en particular con SLUG de parametro
   getVirtualCard(slug: any): Observable<any>{
    const headers = new HttpHeaders().set('content-type', 'application/json');

    return this.http.get(this.url + '/tarjeta-slug/' + slug, {headers});
   }
}
