import {Component, OnInit} from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import {ActivatedRoute} from '@angular/router';
import {VirtualCardService} from './services/virtual-card.service';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit{
  public subscripcion: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private swUpdate: SwUpdate,
    private activatedRoute: ActivatedRoute,
    private cardService: VirtualCardService,
    private metaService: Meta

  ) {

    this.initializeApp();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((event: UpdateAvailableEvent) => {
        if (confirm(`Nueva versión disponible. ¿Cargar nueva versión?`)) {
          window.location.reload();
        }
      });
    }
  }

  ngOnInit(): void {

      //this.activatedRoute.params.subscribe(params => {
        //const slug = params['slug'];
        let urlActualPage = window.location.href;

        let slug = urlActualPage.substring(urlActualPage.lastIndexOf('/') + 1);

        // Se llama al metodo,para obtener la tarjeta, del servicio
        //console.log('slug:' + slug);

        this.subscripcion = this.cardService.getVirtualCard(slug).subscribe(
            response => {
              //console.log(response);
              if (response.tarjeta) {
                //this.titleService.setTitle(response.tarjeta.name + ' ' + response.tarjeta.surname + ' - ' + response.tarjeta.org );
                this.metaService.updateTag({property: 'og:title', content: response.tarjeta.name + ' ' + response.tarjeta.surname});
                this.metaService.updateTag({property: 'og:description', content: response.tarjeta.org + ' - ' + response.tarjeta.title});
              }
              });

    //});

    }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
